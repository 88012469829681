import { inject } from '@angular/core';
import { patchState, signalStoreFeature, withMethods, withState } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';
import { openConfirmDialog } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { openViewEditor } from './components/view-editor-dialog';
import { ViewDto } from './dtos';
import { GridColumn } from './models';

type ViewState<T> = {
    views: ViewDto<T>[];
    selectedView: ViewDto<T> | null;
};

export function withViews<T>(options: { tableIdentifier: string; predefinedViews: ViewDto<T>[] }) {
    const viewsKey = `${options.tableIdentifier}-views`;
    const lastViewIdKey = `${options.tableIdentifier}-last-view`;

    const customViews: ViewDto<T>[] = JSON.parse(localStorage.getItem(viewsKey) ?? '[]');
    const views = [...options.predefinedViews, ...customViews];

    const lastViewId = localStorage.getItem(lastViewIdKey) ?? null;
    const selectedView = views.find((view) => view.id === lastViewId) ?? options.predefinedViews[0] ?? null;

    const initialState: ViewState<T> = {
        views: views,
        selectedView: selectedView,
    };

    return signalStoreFeature(
        withState(initialState),

        withMethods((store, dialogService = inject(DialogService), translate = inject(TranslateService)) => {
            function selectView(view: ViewDto<T>) {
                patchState(store, { selectedView: view });
                localStorage.setItem(lastViewIdKey, view.id);
            }

            function getViewColumns(view: ViewDto<T> | null, allColumns: GridColumn<T>[]): GridColumn<T>[] {
                if (view == null) {
                    return [];
                }

                return allColumns
                    .filter((column) => view.fields.includes(column.field!))
                    .sort((a, b) => view.fields.indexOf(a.field!) - view.fields.indexOf(b.field!));
            }

            async function createView(allColumns: GridColumn<T>[]) {
                await openViewEditor(dialogService, translate, {
                    view: null,
                    allGridColumns: allColumns,
                    onSave: (view) => {
                        saveViews([...store.views(), view]);
                        selectView(view);
                    },
                });
            }

            async function editView(view: ViewDto<T>, allColumns: GridColumn<T>[]) {
                await openViewEditor(dialogService, translate, {
                    view: view,
                    allGridColumns: allColumns,
                    onSave: (view) => {
                        const index = store.views().findIndex((v) => v.id === view.id);
                        if (index <= 0) {
                            return;
                        }

                        const views = [...store.views()];
                        views[index] = view;

                        saveViews(views);
                        selectView(view);
                    },
                });
            }

            async function removeView(view: ViewDto<T>): Promise<void> {
                const accept = await openConfirmDialog(dialogService, {
                    header: translate.instant('viewEditor.confirmation.delete.header'),
                    message: translate.instant('viewEditor.confirmation.delete.message'),
                    acceptLabel: translate.instant('actions.delete'),
                    acceptSeverity: 'danger',
                });
                if (!accept) {
                    return;
                }

                const views = store.views().filter((v) => v.id !== view.id);
                saveViews(views);
                selectView(views[0] ?? null);
            }

            function saveViews(views: ViewDto<T>[]): void {
                patchState(store, { views: views });
                const customViews = views.filter((v) => !v.isStandardView);
                localStorage.setItem(viewsKey, JSON.stringify(customViews));
            }

            return {
                selectView: selectView,
                getViewColumns: getViewColumns,
                createView: createView,
                editView: editView,
                removeView: removeView,
            };
        }),
    );
}
