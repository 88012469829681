import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, model, output, TrackByFunction, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
    FormatNumberPipe,
    LuxonDatePipe,
    LuxonDateTimePipe,
    MoneyPipe,
    Paths,
    StatusBadgeComponent,
} from '@nuis/common';
import { CheckboxModule } from 'primeng/checkbox';
import { Table, TableModule } from 'primeng/table';
import { GridColumn, RowSelection, SelectionMode, Sorting } from './models';

@Component({
    selector: 'nuis-grid',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterModule,
        FormsModule,
        TranslatePipe,
        TableModule,
        CheckboxModule,
        LuxonDatePipe,
        LuxonDateTimePipe,
        MoneyPipe,
        FormatNumberPipe,
        StatusBadgeComponent,
    ],
    templateUrl: './grid.component.html',
})
export class GridComponent<T> {
    public size = input<'small' | 'default'>('default');
    public isLoading = input.required<boolean>();
    public items = input.required<T[]>();
    public total = input.required<number | null>();
    public sorting = input.required<Sorting<T> | null>();
    public showIndexColumn = input<boolean>(true);
    public columns = input.required<GridColumn<T>[]>();
    public sortingChange = output<Sorting<T>>();

    public scrollable = input<boolean>(true);
    public selectionMode = input<SelectionMode | null>(null);
    public selectRow = output<RowSelection<T>>(); // For selection mode `open`
    public selectedItem = model<T | null>(null); // For selection mode `single`

    protected table = viewChild.required(Table);

    protected styleClass = computed<string>(() => {
        return ['z-0', this.size() === 'small' ? 'p-datatable-sm' : ''].join(' ');
    });

    protected handleSortingChange() {
        const table = this.table();
        const sorting = this.sorting();
        if (sorting === null) {
            return;
        }

        // NOTE: Somehow primeng calls this multiple times with the same values
        //       So we need to check if the values actually changed
        if (table.sortField === sorting.field && table.sortOrder === sorting.order) {
            return;
        }

        this.sortingChange.emit({
            field: table.sortField as Paths<T>,
            order: table.sortOrder,
        });
    }

    protected trackByIndex: TrackByFunction<T> = (index: number): number => index;

    protected handleSelectRow(item: T, event: MouseEvent) {
        if (this.selectionMode() != 'open') {
            return;
        }

        this.selectRow.emit({ item: item, ctrlKey: event.ctrlKey });
    }
}
