<div class="flex flex-column h-full overflow-hidden">
    <p-table
        class="flex-1"
        [styleClass]="styleClass()"
        [loading]="isLoading()"
        [value]="items()"
        [totalRecords]="items().length"
        [columns]="columns()"
        [customSort]="true"
        [sortField]="sorting()?.field"
        [sortOrder]="sorting()?.order ?? 0"
        (sortFunction)="handleSortingChange()"
        [resizableColumns]="true"
        [scrollable]="scrollable()"
        [rowTrackBy]="trackByIndex"
        scrollHeight="flex"
        scrollDirection="both"
        responsiveLayout="scroll"
        [virtualScroll]="scrollable()"
        [virtualScrollItemSize]="52"
        [rowHover]="selectionMode() === 'open'"
        [selectionMode]="selectionMode() === 'single' ? 'single' : null"
        [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                @if (showIndexColumn()) {
                    <th></th>
                }

                @for (column of columns(); track $index) {
                    <th [pSortableColumn]="column.field" [pSortableColumnDisabled]="column.sortable !== true">
                        <div
                            class="flex align-items-center"
                            [ngClass]="{
                                'justify-content-end':
                                    column.type === 'currency' || column.type === 'money' || column.rightAlignedHeader,
                            }">
                            @if (column.type === 'template') {
                                @if (column.headerRef; as headerRef) {
                                    <ng-template *ngTemplateOutlet="headerRef" />
                                }
                            }

                            {{ column.label }}

                            @if (column.sortable) {
                                <p-sortIcon style="margin-top: -4px" [field]="column.field" />
                            }
                        </div>
                    </th>
                }
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr
                [ngClass]="{ 'cursor-pointer': selectionMode() === 'open' }"
                [style.height.px]="52"
                [pSelectableRow]="item"
                (click)="handleSelectRow(item, $event)">
                @if (showIndexColumn()) {
                    <td>{{ index + 1 }}.</td>
                }

                @for (column of columns(); track $index) {
                    @switch (column.type) {
                        @case ('text') {
                            <td>
                                <div class="truncate" [style.max-width.px]="column.maxWidth">
                                    {{ item[column.field] }}
                                </div>
                            </td>
                        }
                        @case ('link') {
                            <td>
                                @if (column.external !== true) {
                                    <a
                                        class="custom-link"
                                        [routerLink]="column.route(item)"
                                        (click)="$event.stopPropagation()"
                                        >{{ item[column.field] }}
                                        <i class="pi pi-link"></i>
                                    </a>
                                } @else {
                                    <a
                                        class="custom-link"
                                        [href]="column.route(item).join('/')"
                                        (click)="$event.stopPropagation()"
                                        >{{ item[column.field] }}
                                        <i class="pi pi-link"></i>
                                    </a>
                                }
                            </td>
                        }
                        @case ('date') {
                            <td>{{ item[column.field] | luxonDate }}</td>
                        }
                        @case ('date-time') {
                            <td>{{ item[column.field] | luxonDateTime }}</td>
                        }
                        @case ('checkbox') {
                            <td class="py-0">
                                <p-checkbox [ngModel]="item[column.field]" [disabled]="true" [binary]="true" />
                            </td>
                        }
                        @case ('currency') {
                            <td class="text-right">
                                {{ item[column.field] | money: column.currency(item) }}
                            </td>
                        }
                        @case ('money') {
                            <td class="text-right">
                                @if (item[column.field] !== null && item[column.field] !== undefined) {
                                    {{ item[column.field].amount | money: item[column.field].currency }}
                                }
                            </td>
                        }
                        @case ('reference') {
                            <td class="py-0">
                                @if (item[column.field] !== null && item[column.field] !== undefined) {
                                    @if (column.statusColor?.(item); as statusColor) {
                                        <nuis-status-badge [label]="column.resolve(item)" [color]="statusColor" />
                                    } @else {
                                        {{ column.resolve(item) }}
                                    }
                                }
                            </td>
                        }
                        @case ('template') {
                            <td class="py-0">
                                <ng-template
                                    *ngTemplateOutlet="
                                        column.templateRef;
                                        context: { item: item, column: column, rowIndex: index }
                                    " />
                            </td>
                        }
                        @case ('index-number') {
                            <td>
                                <div>#{{ item[column.field] }}</div>
                            </td>
                        }
                    }
                }
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="text-center" [attr.colspan]="(showIndexColumn() ? 1 : 0) + columns().length">
                    {{ 'noResultsFound' | translate }}
                </td>
            </tr>
        </ng-template>
    </p-table>

    @if (total(); as total) {
        <div class="py-3 px-4 surface-ground text-right border-top-1 surface-border text-500">
            {{
                'pagination2'
                    | translate
                        : {
                              rows: items().length | formatNumber,
                              totalRecords: total | formatNumber,
                          }
            }}
        </div>
    }
</div>
